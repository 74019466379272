import { HttpParams } from "@angular/common/http";

export interface DirectusQueryParams {
    filter?: FilterRule,
    filters?: FilterRule[],
    search?: SearchRule,
    orSearch?: SearchRule[],
    fields?: string,
    page?: {
        page: number,
        size: number
    },
    sort?: string,
    body?: object,
    limit?: number,
    meta?: string,
    aggregate?: AggregateRule,
    deep?: DeepRule
}

export interface FilterRule {
    column: string,
    operator: string,
    value: string | Array<string>
}

export interface SearchRule {
    column: string,
    word: string
}

export interface AggregateRule {
    operator: string,
    value: string
}

export interface DeepRule {
    _sort?: string,
    _limit?: number,
}

export function setPathParams(apiParams: DirectusQueryParams) : HttpParams {
    let params = new HttpParams()

    const generateColumns = (column:string) => (column.split('.').map((item:string) => `[${item}]`)).join('')

    params.set('limit', apiParams['limit'] ? apiParams['limit'] : -1)
    
    if ('meta' in apiParams) {
        params = params.set('meta', apiParams['meta'] ? apiParams['meta'] : '*')
    }

    if ('sort' in apiParams) {          
        params = params.set('sort', apiParams['sort'] ? apiParams['sort'] : '')
    }

    if ('page' in apiParams) {
        params = params.set('limit', apiParams['page'] ? apiParams['page']['size'] : 200).set('page', apiParams['page'] ? apiParams['page']['page'] : 1)
        params = params.set('meta', 'filter_count')
    }

    if (apiParams['search'] && apiParams['search']['word'] != '') {
        params = params.set(`filter${generateColumns(apiParams['search']['column'])}[_contains]`, apiParams['search']['word'])
    }

    if (apiParams['orSearch'] && apiParams['orSearch'].length > 0) {
        apiParams['orSearch'].forEach((search, index) => {
            if(search['word'] != null && search['word'] != '') params = params.set(`filter[_or][${index}]${generateColumns(search['column'])}[_icontains]`, search['word'])
        })
    }

    if (apiParams['filter'] && apiParams['filter']['value'] != '') {
        if(typeof apiParams['filter']['value'] == 'string'){
            params = params.set(`filter${generateColumns(apiParams['filter']['column'])}[${apiParams['filter']['operator']}]`, apiParams['filter']['value'])
        }
        else if(typeof apiParams['filter']['value'] == 'string') {
            console.log('array');
        }
    }
    

    if (apiParams['filters'] && apiParams['filters'].length > 0) {
        apiParams['filters'].forEach((filter) => {
            if(filter && typeof filter['value'] == 'string' && filter['value'] != '') params = params.set(`filter${generateColumns(filter['column'])}[${filter['operator']}]`, filter['value'])
        })
    }  

    if (apiParams['fields']) {
        params = params.set(`fields`, apiParams['fields'])
    }

    if (apiParams['aggregate'] && apiParams['aggregate']['value'] != '') {
        params = params.set(`aggregate[${apiParams['aggregate']['operator']}]`, apiParams['aggregate']['value']);
    }

    if (apiParams['deep']) {
        if(apiParams['deep']['_sort']) {
            params = params.set(`deep[sessionsDataExercises][_sort]`, apiParams['deep']['_sort'] );
        }
        if(apiParams['deep']['_limit']) {
            params = params.set(`deep[sessionsDataExercises][_sort]`, apiParams['deep']['_limit'] );
        }
    }

    return params
}
<!-- start: PATIENT WIZARD -->
<app-patient-wizard *ngIf="patientHeader.wizardActiveIndex !== undefined" [activeIndex]="patientHeader.wizardActiveIndex"></app-patient-wizard>
<!-- end: PATIENT WIZARD -->


<!-- start: PATIENT VIEW DETAIL -->
<app-patient-view-detail *ngIf="patientHeader.viewDetail != undefined"
    [avatarBackButton]="patientHeader.viewDetail.avatarBackButton" [extendedDetail]="patientHeader.viewDetail.extendedDetail" 
    [showPatientNumber]="patientHeader.viewDetail.showPatientNumber" [showCard]="patientHeader.viewDetail.showCard"
>
</app-patient-view-detail>
<!-- end: PATIENT VIEW DETAIL -->


<!-- start: PATIENT SUBTITLE -->
<div class="flex sm:flex align-items-center sm:align-items-center gap-3 sm:gap-3 mr-3 cursor-pointer mt-3" (click)="goBack()" *ngIf="patientHeader.subtitle">
    <i class="pi pi-angle-left text-xl"></i>
    <p class="text-2xl font-semibold" i18n>{{ patientHeader.subtitle }}</p>
</div>
<!-- end: PATIENT SUBTITLE -->
import { Component } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent {

    constructor(
        private layoutService: LayoutService
    ) {

    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }

}

<div class="grid">
    <div class="sm:col-12 md:col-12 lg:col-8 xl:col-6 lg:col-offset-2 xl:col-offset-3 px-3 py-5">
        <div class="px-3 py-5">

            <h2 class="text-center" i18n>AVISO LEGAL</h2>
            <p i18n>
                El acceso, y/o utilización de la aplicación de “Fundación UNOENTRECIENMIL” (en adelante, la “<b>Aplicación</b>”) implica la aceptación expresa y sin reservas de todos los términos del presente Aviso Legal, teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado.Su observancia y cumplimiento será exigible respecto de cualquier persona que acceda, descargue y/o utilice la Aplicación. Si Ud. no está de acuerdo con los términos expuestos, no acceda, descargue y/o utilice la Aplicación. 	
            </p>

            <h3 i18n>1. DATOS DE LA COMPAÑÍA.</h3>
            <div>
                <ul class="list-disc">
                    <li i18n>Titular: Fundación UNOENTRECIENMIL (en adelante, “UNOENTRECIENMIL”)</li>
                    <li i18n>NIF: G-86524741</li>
                    <li i18n>Domicilio social: Avenida de Pablo Iglesias, 17 - sótano 1 28003, Madrid.</li>
                    <li i18n>Dirección de correo electrónico: info&#64;unoentrecienmil.org</li>
                </ul>
            </div>

            <h3 i18n>2. OBJETO Y ÁMBITO DE APLICACIÓN.</h3>
            <p i18n>Las presentes condiciones de uso regulan la descarga, acceso y utilización de la Aplicación, sin perjuicio de que UNOENTRECIENMIL se reserva el derecho a modificar la presentación, configuración y contenido de la aplicación, así como las condiciones requeridas para su descarga, acceso y/o utilización. La descarga, acceso y/o utilización de los contenidos de la Aplicación tras la entrada en vigor de sus modificaciones o cambios suponen la aceptación de los mismos.</p>
            <p i18n>No obstante, el acceso a determinados contenidos y la utilización de determinados servicios puede encontrarse sometido a determinadas condiciones particulares, que serán, en todo caso, claramente mostradas y deberán ser aceptadas expresamente por parte de los usuarios. Estas condiciones particulares, podrán sustituir, completar o, en su caso, modificar las presentes condiciones de uso particulares.</p>
            <p i18n>UNOENTRECIENMIL se reserva el derecho a modificar los términos y condiciones aquí estipuladas, total o parcialmente, publicando cualquier cambio en la misma forma en que aparecen estas condiciones de uso o a través de cualquier tipo de comunicación dirigida a los usuarios.</p>
        
            <h3 i18n>3. ACCESO Y REGISTRO.</h3>
            <p i18n>La descarga es libre, y respecto al acceso y utilización de la Aplicación se requiere del código de usuario que será facilitado al mismo a través de un facultativo usuario de la plataforma. El acceso y uso de la aplicación es gratuito.</p>
            <p i18n>El usuario, deberá registrarse una vez descargada la aplicación, donde se le requerirá la introducción del código que le será suministrado, dado que supone una información esencial para poder gestionar correctamente nuestro servicio.</p>
            <p i18n>Queda prohibido el uso y participación en las actividades de la Aplicación a menores de 18 años, de conformidad con lo dispuesto en los apartados siguientes de las presentes Condiciones.</p>
            <p i18n>Bajo ningún concepto la Aplicación se responsabilizará de la veracidad de los datos de registro facilitados por los usuarios finales, por lo que cada uno de éstos son los responsables de las posibles consecuencias, errores y fallos que posteriormente puedan derivarse de la falta de veracidad de los datos.</p>
        
            <h5 class="pl-4" i18n>3.1. Requisitos para registrarse como usuario.</h5>
            <div class="pl-4">
                <p i18n>Es requisito imprescindible para poder registrarse y utilizar la Aplicación acceder con el código que se le brinde al usuario.</p>
                <p i18n>Del mismo modo, el usuario registrado asume que su cuenta de usuario es personal e intransferible.</p>
                <p i18n>Bajo ningún concepto está permitida la cesión de la cuenta de usuario entre diferentes personas, quedando a disposición de la Aplicación la eliminación de la misma tan pronto sea detectada tal circunstancia, sin perjuicio de que se reserva las acciones legales que pudieran corresponder.</p>
            </div>

            <h5 class="pl-4" i18n>3.2. Aceptación de permisos.</h5>
            <div class="pl-4">
                <p i18n>Para la correcta actividad de la Aplicación, será pertinente que el usuario acepte los siguientes permisos que se requieren en el momento del registro como usuario:</p>
                <div>
                    <ul class="list-disc">
                        <li i18n>Permiso para el envío y lectura de mensajes SMS por parte de SEARCHING HELP.</li>
                        <li i18n>Permiso para la habilitación de estadísticas de uso, accesibilidad del servicio, notificaciones de servicio y agregar a la lista blanca.</li>
                        <li i18n>Permiso de acceso a los datos de uso.</li>
                        <li i18n>Permiso de acceso a las notificaciones del usuario con una interacción directa.</li>
                        <li i18n>Permiso de que la aplicación esté activa en segundo plano.</li>
                        <li i18n>Permiso de acceso total de la aplicación al dispositivo.</li>
                    </ul>
                </div>
            </div>

            <h5 class="pl-4" i18n>3.3. Baja como usuario.</h5>
            <div class="pl-4">
                <p i18n>Los usuarios pueden solicitar la baja de su cuenta en la Aplicación, mediante comunicación escrita a aceleradora&#64;unoentrecienmil.org indicando su nombre de usuario y el código que le fue facilitado.</p>
                <p i18n>En cualquier caso, una vez efectuada la baja, el usuario podrá solicitar un nuevo registro como usuario, quedando reservada la facultad de SEARCHING HELP de no admitir dicho registro en los casos concretos especificados en la cláusula denominada “Usos no autorizados” o en caso de conflicto o controversia suscitados entre las partes, por resolver o finalizado con reconocimiento de culpa o negligencia del usuario y/o perjuicio a SEARCHING HELP, a sus colaboradores o sus usuarios.</p>
            </div>

            <h3 i18n>4. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h3>
            <p i18n>UNOENTRECIENMIL es titular de los derechos de explotación de propiedad intelectual e industrial de la Aplicación, así como de los derechos de propiedad intelectual e industrial sobre la información, materiales y contenidos de la misma.</p>
            <p i18n>En ningún caso, se entenderá que la descarga, acceso y utilización de la Aplicación por parte del usuario implica una renuncia, transmisión, licencia o cesión total o parcial de dichos derechos por parte de UNOENTRECIENMIL. El usuario dispone de un derecho de uso de los contenidos y servicios de la Aplicación dentro de un ámbito estrictamente doméstico.</p>
            <p i18n>Las referencias a marcas o nombres comerciales registrados, u otros signos distintivos, ya sean titularidad de UNOENTRECIENMIL, llevan implícitas la prohibición sobre su uso sin el consentimiento de UNOENTRECIENMIL. En ningún momento, salvo manifestación expresa en contrario, el acceso, descarga o utilización de la aplicación y/o de sus contenidos, confiere al usuario derecho alguno sobre signos distintivos en él incluidos.</p>
            <p i18n>UNOENTRECIENMIL, garantiza que el contenido propio publicado cumple con las exigencias legales requeridas sin infringir ningún derecho de propiedad intelectual, industrial, secreto comercial o cualesquiera otros derechos de terceros, sin que pueda ser considerado de carácter confidencial, ni es perjudicial para terceros. Para el contenido de terceros, UNOENTRECIENMIL, garantiza que cuenta con las autorizaciones correspondientes, tal y como se es titular o, en su caso, cuenta con las licencias correspondientes sobre los derechos de explotación de propiedad intelectual e industrial de la Aplicación, a través de la cual se tendrá acceso a todo el contenido editorial publicado.</p>
            <p i18n>Si un Usuario conociera de la existencia de contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de derechos de propiedad intelectual, industrial, o de cualquier otra índole, rogamos que lo notifique inmediatamente a UNOENTRECIENMIL a través de la dirección de correo electrónico aceleradora&#64;unoentrecienmil.org para que éste pueda proceder a la adopción de las medidas oportunas.</p>
            <p i18n>En el caso de que el usuario envíe información de cualquier tipo a UNOENTRECIENMIL a través de cualquiera de los canales habilitados al efecto, el usuario declara, garantiza y acepta que tiene derecho a hacerlo libremente, que dicha información no infringe ningún derecho de propiedad intelectual, industrial, secreto comercial o cualesquiera otros derechos de terceros, y que dicha información no tiene carácter confidencial ni es perjudicial para terceros.</p>
            <p i18n>El usuario reconoce asumir la responsabilidad, dejando indemne a UNOENTRECIENMIL por cualquier comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna la exactitud, legalidad, originalidad y titularidad de la misma.</p>

            <h3 i18n>5. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h3>
            <p i18n>No está permitido y, por tanto, sus consecuencias serán de la exclusiva responsabilidad del usuario, el acceso, descarga o la utilización de la Aplicación con fines ilegales o no autorizados, con o sin finalidad económica, y, más específicamente y sin que el siguiente listado tenga carácter absoluto, queda prohibido:</p>
            <div>
                <ul class="list-disc">
                    <li i18n>Usar la Aplicación en forma alguna que pueda provocar daños, interrupciones, ineficiencias o defectos en su funcionalidad o en el dispositivo de un tercero;</li>
                    <li i18n>Usar la Aplicación para la transmisión, la instalación o la publicación de cualquier virus, código malicioso u otros programas o archivos perjudiciales;</li>
                    <li i18n>Usar la Aplicación para recoger datos de carácter personal de otros usuarios;</li>
                    <li i18n>Registrarse en la Aplicación con una identidad falsa, suplantado a terceros, utilizando un perfil o realizando cualquier otra acción que pueda confundir sobre la identidad del usuario;</li>
                    <li i18n>Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación de la Aplicación o de cualquier red conectada a la misma, o las medidas de seguridad o protección inherentes a los contenidos ofrecidos en la aplicación;</li>
                    <li i18n>Llevar a cabo alguna acción que provoque una saturación desproporcionada o innecesaria en la infraestructura de la Aplicación o en los sistemas o redes de SEARCHING HELP, así como en los sistemas y redes conectados a la Aplicación;</li>
                    <li i18n>Impedir el normal desarrollo de cualquier actividad disponible a través de la Aplicación o cualesquiera de sus funcionalidades, ya sea alterando o tratando de alterar, ilegalmente o de cualquier otra forma, el acceso, descarga o funcionamiento, o utilizando métodos fraudulentos, mediante cualquier procedimiento, y/o a través de cualquier práctica que atente o vulnere en modo alguno las presentes Condiciones de uso.</li>
                </ul>
            </div>
            <p i18n>El incumplimiento de cualquiera de las anteriores obligaciones por el usuario podrá llevar aparejada la adopción por UNOENTRECIENMIL de las medidas oportunas amparadas en Derecho y en el ejercicio de sus derechos u obligaciones, pudiendo llegar a la eliminación o bloqueo de la cuenta del usuario infractor, sin que medie posibilidad de indemnización alguna por los daños y perjuicios causados.</p>

            <h3 i18n>6. RESPONSABILIDADES Y GARANTÍAS.</h3>
            <p i18n>UNOENTRECIENMIL no puede garantizar la fiabilidad, utilidad o veracidad de absolutamente toda la información y/o de los servicios la aplicación, ni tampoco de la utilidad o veracidad de la documentación puesta a disposición a través de la misma.</p>
            <p i18n>En consecuencia, UNOENTRECIENMIL no garantiza ni se hace responsable de: (i) la continuidad de los contenidos la Aplicación; (ii) la ausencia de errores en dichos contenidos; (iii) la ausencia de virus y/o demás componentes dañinos en la aplicación o en el servidor que lo suministra; (iv) la invulnerabilidad de la aplicación y/o la imposibilidad de vulnerar las medidas de seguridad que se adopten en la misma; (v) la falta de utilidad o rendimiento de los contenidos de la Aplicación; y (vi) los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que UNOENTRECIENMIL establece en la Aplicación o a través de la vulneración de los sistemas de seguridad dicha aplicación.</p>
            <p i18n>No obstante, UNOENTRECIENMIL declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la técnica, para garantizar el funcionamiento de la Aplicación y reducir al mínimo los errores del sistema, tanto desde el punto de vista técnico, como de los contenidos publicados en la aplicación.</p>
            <p i18n>Si el usuario tuviera conocimiento de la existencia de algún contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de derechos de propiedad intelectual o industrial, de la normativa aplicable en material de protección de datos personales y/o cualquier otro derecho, deberá notificarlo inmediatamente a UNOENTRECIENMIL para que ésta pueda proceder a la adopción de las medidas oportunas.</p>
            <p i18n>En todo caso, UNOENTRECIENMIL se reserva el derecho a suspender, modificar, restringir o interrumpir, temporal o permanentemente, el acceso, descarga, uso, alojamiento y/o descarga del contenido y/o uso de servicios de la Aplicación, con o sin previa notificación, a los usuarios que contravengan cualquiera de las disposiciones detalladas en las presentes condiciones de uso, sin que medie posibilidad del usuario de exigir indemnización alguna por esta causa.</p>

            <h3 i18n>7. POLÍTICA DE PRIVACIDAD Y COOKIES.</h3>
            <p i18n>De conformidad con lo dispuesto por la legislación vigente y aplicable en materia de protección de datos de carácter personal, todos los datos de carácter personal facilitados durante la descarga, acceso y utilización de la Aplicación serán tratados de conformidad con los dispuesto en la Política de Privacidad que todo usuario debe aceptar expresamente para poder descargar, registrarse y utilizar la aplicación.</p>
            <p i18n>Todo usuario que acepte las presentes condiciones de uso acepta de forma informada, expresa e inequívoca nuestra Política de Privacidad y Política de Cookies, en el caso de existir, pudiendo ejercer los derechos que en esta materia le corresponden, según se informa en las mencionadas Políticas.</p>

            <h3 i18n>7. LEY APLICABLE Y JURISDICCIÓN COMPETENTE.</h3>
            <p i18n>Estas Condiciones de Uso se rigen por la ley española. Las partes se someten expresamente, para la resolución de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales de Madrid. </p>
            <p i18n>Para presentar reclamaciones en el uso de nuestros servicios el usuario puede dirigirse por correo a la dirección electrónica a la siguiente dirección: aceleradora&#64;unoentrecienmil.org , comprometiéndonos a buscar en todo momento una solución amistosa del conflicto.</p>

            <p class="font-bold pt-6" i18n>Última actualización Abril 2022<br>
                2022 © Fundación UNOENTRECIENMIL. Todos los derechos reservados
            </p>

        </div>
    </div>
</div>
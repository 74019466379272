import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DirectusService } from 'src/app/core/services/directus.service';
import { DirectusQueryParams } from 'src/app/shared/interfaces/directus-query-params.interface';
import { Therapist } from '../models/therapist.model';

@Injectable({
  providedIn: 'root'
})
export class TherapistService {
    private apiParams: DirectusQueryParams = { 
        fields: `id, projects, 
                user.first_name, user.last_name, user.avatar, user.email, user.language`, 
        sort: 'user.first_name'
    };
    private endpoint = '/items/therapists';

    constructor(
        private directusService: DirectusService<Therapist>
    ) { }

    index(apiParams?: DirectusQueryParams): Observable<Therapist[]> {
        const params = (apiParams) ? { ...this.apiParams, ...apiParams } : this.apiParams;
        return this.directusService.index(this.endpoint, params);
    }
}

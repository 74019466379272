import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, Subscription, of } from 'rxjs';
import { map, catchError, filter, concatMap, first, tap } from 'rxjs/operators';
import { User } from '../../../shared/models/user.model';
import { AuthModel } from '../models/auth.model';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { TherapistService } from '../../patient/services/therapist.service';
import { DirectusQueryParams } from 'src/app/shared/interfaces/directus-query-params.interface';
import { Therapist } from '../../patient/models/therapist.model';

export type UserType = User | undefined;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    // private fields
    private unsubscribe: Subscription[] = [];
    
    // public fields
    public currentUser$: Observable<UserType>;
    public currentUserSubject: BehaviorSubject<UserType>;
    public isLoggedIn = false;

    get currentUserValue(): UserType {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserType) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService,
        private therapistService: TherapistService
    ) {
        // Ser user
        let user = undefined;
        if(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER)){
            user = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER)) as User;
        }
        this.currentUserSubject = new BehaviorSubject<UserType>(user);
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    login(email: string, password: string): Observable<any> {
        const params = { email:email, password:password };

        return this.httpClient.post<any>(`${environment.apiHost}/auth/login`, params).pipe(
            filter(data => (typeof data === 'object') || data == false),
            tap((res: any) => this._setAuth(res)),
            map(() => this.isLoggedIn),
            concatMap(() => this._retrieveUser()),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    tokenExpired(){
        if(this.localStorageService.getData('authToken')){
            var token = JSON.parse(this.localStorageService.getData('authToken'));
            const expiry = (JSON.parse(atob(token.token.split('.')[1]))).exp;
            return (Math.floor((new Date).getTime() / 1000)) >= expiry;
        }
        return true;
    }

    logout() {
        this.localStorageService.removeData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN);
        this.localStorageService.removeData(AppConstants.LOCAL_STORAGE_CURRENT_USER);
        this.router.navigate(['/auth/login'], { queryParams: {} });
    }

    registration() {}

    forgotPassword(email: string): Observable<any> {
        const params = { email:email };

        return this.httpClient.post<any>(`${environment.apiHost}/auth/password/request`, params).pipe(
            filter(data => (typeof data === 'object') || data == false),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }  



    /***************************
    ***** PRIVATE FUNCTIONS ****
    ****************************/    
    private _retrieveUser(): Observable<any> {
        console.log('retrieve user info....');
        if (!this.isLoggedIn) return of(false)
        console.log('retrieve user info ENTERED');

        return this.httpClient.get(`${environment.apiHost}/users/me`)
            .pipe(
                catchError((error: any, caught: Observable<any>): Observable<any> => {
                    console.error('There was an error!', error);
                    return of(false);
                }),
                map((res) => this._setCurrentUser(res)),
                concatMap(() => this._retrieveTherapist()),
                catchError(error => {
                    return throwError(() => error);
                })
            );
    }

    private _retrieveTherapist(): Observable<any> {
        console.log('retrieve therapist info....');
        if (!this.isLoggedIn || !this.currentUserValue) return of(false)
        console.log('retrieve therapist info ENTERED');

        const apiParams: DirectusQueryParams = { 
            filter: { column: 'user.id', operator: '_eq', value:this.currentUserValue.id }
        };
        return this.therapistService.index(apiParams).pipe(
            first(),
            map((therapists) => {
                if(therapists.length > 0) this._setCurrentTherapist(therapists[0]);
                return therapists;
            })
        );
    }

    private _setAuth(res:any) {
        // Set authentication data
        let auth = new AuthModel();
        auth.accessToken = res.access_token;
        auth.refreshToken = res.refresh_token;
        auth.expiresAt = new Date(Date.now() + res.expires);

        // store auth acessToken/refreshToken/expiresAt in local storage to keep user logged in between page refreshes
        if (auth && auth.accessToken) {
            this.localStorageService.setData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN, JSON.stringify(auth));
        }

        this.isLoggedIn = true;
    }

    private _setCurrentUser(res:User) {
        this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_USER, JSON.stringify(res));
        this.currentUserSubject.next(res);
        if(!environment.production) console.log(res);

        return res
    }

    private _setCurrentTherapist(therapist:Therapist) {       
        if(!this.currentUserValue) return;

        this.currentUserValue.therapist = therapist;
        this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_USER, JSON.stringify(this.currentUserValue));
        this.currentUserSubject.next(this.currentUserValue);

        if(!environment.production) console.log(this.currentUserValue);

        return this.currentUserValue
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}

import { Component } from '@angular/core';

@Component({
	selector: 'app-legal-notice',
	templateUrl: './legal-notice.page.html',
	styleUrls: ['./legal-notice.page.scss']
})
export class LegalNoticePage {
	
}

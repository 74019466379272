export class AppConstants {

    // Local storage variables
    public static LOCAL_STORAGE_LOCALIZATION_LOCALE = 'language';
    public static LOCAL_STORAGE_AUTH_TOKEN          = 'authToken';
    public static LOCAL_STORAGE_CURRENT_USER        = 'currentUser';

    // General constants
    public static ITEMS_PER_PAGE = 10;
    public static HOME_URL = '/patient';
}
import { Injectable } from '@angular/core';
import { DirectusQueryParams } from 'src/app/shared/interfaces/directus-query-params.interface';
import { Session } from '../models/session.model';
import { Observable, first, map } from 'rxjs';
import { DirectusService } from 'src/app/core/services/directus.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private apiParams: DirectusQueryParams = { 
        fields: `id, patient.id, patient.user.first_name, patient.user.last_name, dateStart, hourStart, numSession, sessionName, numWeekSession, duration, status`,
        sort: 'numSession'
    };
    private apiParamsView: DirectusQueryParams = { 
        fields: `id, patient.id, patient.user.first_name, patient.user.last_name, patient.user.avatar, patient.centers.centers_id.id, patient.centers.centers_id.name, 
                patient.maxHR, dateStart, hourStart, numSession, numWeekSession, duration, sessionName, sessionType, status,
                sessionsData.id, sessionsData.sessionsDataExercises.id`
    };
    private endpoint = '/items/sessions';

    constructor(
        private directusService: DirectusService<Session>
    ) { }

    count(apiParams?: DirectusQueryParams): Observable<number> {
        const params = (apiParams) ? { ...this.apiParams, ...apiParams } : this.apiParams;
        return this.directusService.index(this.endpoint, params).pipe(
            first(),
            map((session:any) => {
                return (session.length > 0) ? parseInt(session[0]['count']) : 0;
            })
        );
    }

    index(apiParams?: DirectusQueryParams): Observable<Session[]> {
        const params = (apiParams) ? { ...this.apiParams, ...apiParams } : this.apiParams
        return this.directusService.index(this.endpoint, params);
    }

    create(params:any): Observable<Session> {
        params = this._parseParams(params);
        return this.directusService.create(this.endpoint, params);
    }

    view(id:string): Observable<Session> {
        return this.directusService.view(this.endpoint, id, this.apiParamsView).pipe(map((session:Session) => {
            // Parse hourStart and dateStart from string to Date format
            if(session.hourStart) session.hourStart = new Date(session.dateStart +' '+ session.hourStart);
            session.dateStart = new Date(session.dateStart);
            return session;
        }));
    }

    patch(id:string, params:any): Observable<Session> {
        params = this._parseParams(params);
        return this.directusService.patch(this.endpoint, id, params, this.apiParamsView);
    }

    delete(id:string): Observable<Session> {
        return this.directusService.delete(this.endpoint, id);
    }


    /***************************
    ***** PRIVATE FUNCTIONS ****
    ****************************/
    _parseParams(params:any) {
        // Set default value if the hourStart is empty
        if(!params.hourStart) {
            params.hourStart = '10:00';
        }
        // Parse hourStart to string if the value is a Date
        else if(params.hourStart && typeof params.hourStart === 'object'){
            params.hourStart = params.hourStart.getHours() + ':' + params.hourStart.getMinutes();
        }

        // Remove timeZone from dateStart
        if(params.dateStart) {
            var userTimezoneOffset = params.dateStart.getTimezoneOffset() * 60000;
            params.dateStart = new Date(params.dateStart.getTime() - userTimezoneOffset);    
        }
        
        return params;
    }

    
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DirectusQueryParams, setPathParams } from 'src/app/shared/interfaces/directus-query-params.interface';
import { DirectusFile } from 'src/app/shared/models/directus-file.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DirectusFileService {
    private apiParams: DirectusQueryParams = { 
        fields: `id, filename_disk, filename_download, title, type, width, height, description, metadata, 
                folder.id, folder.name`, 
        sort: 'title'
    };
    private endpoint = '/files';

    constructor(
        private httpClient: HttpClient
    ) { }

    public index(apiParams?: DirectusQueryParams): Observable<DirectusFile[]> {
        if(apiParams) this.apiParams = { ...this.apiParams, ...apiParams };
        return this.httpClient.get<DirectusFile[]>(`${environment.apiHost}${this.endpoint}`, { params: setPathParams(this.apiParams) }).pipe(
            map((res:DirectusFile[]) => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
    
    public upload(files:File[], title:string = '', folder?:string): Observable<DirectusFile | DirectusFile[]> {
        const formData = new FormData();
        files.map((file:File) => formData.append('file', file));
        if (title != '') formData.append('title', title);
        if (folder) formData.append('folder', folder);

        return this.httpClient.post<DirectusFile | DirectusFile[]>(`${environment.apiHost}${this.endpoint}`, formData).pipe(
            map((files:DirectusFile[] | DirectusFile) => {
                return files;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
    
    public delete(id:string) {
        return this.httpClient.delete(`${environment.apiHost}${this.endpoint}/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}

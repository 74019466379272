import { Component, Inject, Input } from '@angular/core';
import { Session } from '../../models/session.model';
import { Patient } from 'src/app/modules/patient/models/patient.model';
import { environment } from 'src/environments/environment';
import { DirectusFile } from 'src/app/shared/models/directus-file.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
    selector: 'app-session-card',
    templateUrl: './session-card.component.html',
    styleUrls: ['./session-card.component.scss']
})
export class SessionCardComponent {

    @Input() session:Session;
    @Input() showEditButton:boolean = false;
    @Input() extraInfo:boolean = false;

    public patient:Patient;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sessionService: SessionService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        if(!this.session){
            this.router.events.pipe(
                map(e => this._getParams(this.activatedRoute)),
                take(1)
            ).subscribe((params:any) => {
                this.sessionService.view(params.id).subscribe((session:Session) => {
                    this.session = session
                    this.patient = this.session.patient;
                });
            });
        }
        else {
            this.patient = this.session.patient;
        }
    }

    getAvatar(patient:Patient) {
        if(patient.user.avatar) return environment.apiHost + '/assets/' + (patient.user.avatar as DirectusFile);
        else return '/assets/media/avatars/blank.png';
    }

    confirmDialog(event: Event) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: $localize`Eliminar sesión`,
            header: $localize`Estas seguro que deseas eliminar esta sesión?`,
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass:"p-button-danger p-button-text",
            rejectButtonStyleClass:"p-button-link",
            acceptIcon:"none",
            rejectIcon:"none",
            acceptLabel: $localize`Eliminar`,
            accept: () => {
                this.sessionService.delete(this.session.id).subscribe(() => {
                    this.router.navigate(['/session']);
                    this.messageService.add({ severity: 'success', summary: $localize`Sesión eliminada`, detail: $localize`La sesión ha sido eliminada correctamente` });
                });
            },
            reject: () => {
            }
        });
    }


    /***************************
    ***** PRIVATE FUNCTIONS ****
    ****************************/
    private _getParams(route: ActivatedRoute): Params {
		// route param names (eg /a/:personId) must be ditinct within
		// a route otherwise they'll be overwritten
		let params = route.snapshot.params
		params = { ...route.snapshot.queryParams, ...params}
		if(route.children){
			for(let r of route.children){
				params = {...this._getParams(r), ...params};        
			}
		}
		return params;
	}
}

import { NgModule } from '@angular/core';
import { RequiredComponent } from './components/required/required.component';
import { TimeSincePipe } from './pipes/time-since.pipe';
import { SexPipe } from './pipes/sex.pipe';
import { ContactRelationPipe } from './pipes/contact-relation.pipe';
import { PatientGroupPipe } from './pipes/patient-group.pipe';
import { LegalNoticePage } from './pages/legal-notice/legal-notice.page';
import { CommonModule } from '@angular/common';  
import { SessionStatusPipe } from './pipes/session-status.pipe';
import { SessionTypePipe } from './pipes/session-type.pipe';
import { SessionStatusComponent } from '../modules/session/components/session-status/session-status.component';
import { ChipModule } from 'primeng/chip';
import { SessionCardComponent } from '../modules/session/components/session-card/session-card.component';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { NoteListComponent } from '../modules/note/components/note-list/note-list.component';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [
		RequiredComponent,
		TimeSincePipe,
		SexPipe,
		ContactRelationPipe,
		PatientGroupPipe,
        SessionStatusPipe,
        SessionTypePipe,
		LegalNoticePage,
        SessionStatusComponent,
        SessionCardComponent,
        NoteListComponent
	],
    imports: [
        CommonModule,
        RouterModule,
        ChipModule,
        AvatarModule,
        ButtonModule,
        DropdownModule,
        ReactiveFormsModule
    ],
    exports: [
		TimeSincePipe,
		SexPipe,
		ContactRelationPipe,
		PatientGroupPipe,
        SessionStatusPipe,
        SessionTypePipe,
        RequiredComponent,
        SessionStatusComponent,
        SessionCardComponent,
        NoteListComponent
	]
})
export class SharedModule { }
<div class="flex flex-column bg-white p-4 border-round shadow-1" *ngIf="session && patient">
    <div class="flex flex-row align-items-start gap-2">
        <p-avatar [image]="getAvatar(patient)" styleClass="mr-2 patient-avatar" size="xlarge" shape="circle" [style]="{'width': '56px', 'height': '56px'}"></p-avatar>
        <div class="flex flex-row sm:flex-row justify-content-between align-items-center flex-1 gap-4 mt-1">
            <div class="flex flex-column align-items-start sm:align-items-start">
                <div class="text-xl font-bold">{{ patient.user.first_name + ' ' + patient.user.last_name }}</div>
                <div class="flex justify-content-between flex-wrap py-3">
                    <div class="flex align-items-center justify-content-center text-sm font-normal">
                        <span class="pr-4"><i class="pi pi-calendar pr-2"></i>{{ session.dateStart | date }}</span>
                        <span class="pr-4"><i class="pi pi-clock pr-2"></i>{{ session.hourStart | date:'shortTime' }}</span>
                        <span><i class="pi pi-list pr-2"></i>{{ 'Sesión ' + session.numSession }}</span>
                            
                    </div>
                </div>
                <div class="mt-2" *ngIf="extraInfo">
                    <p class="mb-1">
                        <i class="pi pi-hourglass pr-2"></i>
                        <span class="font-semibold" i18n>Duración de la sessión</span> {{ session.duration }} <span>minutos</span>
                    </p>
                    <p class="mb-1">
                        <i class="pi pi-bolt pr-2"></i>
                        <span class="font-semibold" i18n>Nombre de la sesión</span> {{ session.sessionName }}
                    </p>
                    <p class="mb-1">
                        <i class="pi pi-building pr-2"></i>
                        <span class="font-semibold" i18n>Ubicación</span> {{ session.sessionType | sessionType }}
                    </p>
                </div> 
            </div>
        </div>
    </div>
    <div class="fluid m-2" *ngIf="showEditButton"> 
        <div class="flex justify-content-between flex-wrap mt-2">
            <div class="flex align-items-center justify-content-center">
                
            </div>
            <div class="flex align-items-center justify-content-center">
                <p-button label="Eliminar" icon="pi pi-times" styleClass="p-button-link" i18n-label (click)="confirmDialog($event)"></p-button>
                <button pButton pRipple type="button" class="p-button-secondary border-round-4xl" [routerLink]="['/session', session.id, 'edit']" icon="pi pi-pencil" label="Editar" i18n-label></button>
            </div>
        </div>
    </div>
</div>
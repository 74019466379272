import { Injectable } from '@angular/core';
import { Patient } from '../models/patient.model';
import { DirectusQueryParams } from 'src/app/shared/interfaces/directus-query-params.interface';
import { Observable, first, map, switchMap } from 'rxjs';
import { DirectusService } from 'src/app/core/services/directus.service';
import { DirectusFile } from 'src/app/shared/models/directus-file.model';
import { DirectusFileService } from 'src/app/core/services/directus-file.service';

@Injectable({
    providedIn: 'root',
    
})
export class PatientService {
    private apiParams: DirectusQueryParams = { 
        fields: `id, birth, sex, historyNumber, patientNumber, group, diagnosis.id, diagnosis.name, diagnosisDetails.id, diagnosisDetails.name, diagnosisDetails.diagnosis, 
                centers.centers_id.name, sessions.numSession, sessions.dateStart,  
                user.id, user.first_name, user.last_name, user.avatar.id, user.role.id, 
                contacts.contacts_id.id, contacts.contacts_id.mainContact, contacts.contacts_id.relation, contacts.contacts_id.name, contacts.contacts_id.phone, 
                contacts.contacts_id.email, contacts.contacts_id.notes, 
                therapists.therapists_id.user.id, therapists.therapists_id.user.avatar, therapists.therapists_id.user.first_name, therapists.therapists_id.user.last_name`, 
        sort: 'sessions.numSession'
    };
    private apiParamsView: DirectusQueryParams = { 
        fields: `id, birth, sex, historyNumber, patientNumber, group, oncologist, diagnosis.id, diagnosis.name, diagnosisDetails.id, diagnosisDetails.name, diagnosisDetails.diagnosis, 
                centers.centers_id.*, group, stage.id, stage.name, risk.id, risk.name, treatmentProtocol.*, 
                diagnosisDay, relapse, metastasisLocations.metastasisLocations_id.id, metastasisLocations.metastasisLocations_id.name, metastasisDetails, 
                suspectToDiagnosisDays, notes, maxHR, 
                user.id, user.first_name, user.last_name, user.email, user.avatar.*, user.role.*, 
                contacts.contacts_id.id, contacts.contacts_id.mainContact, contacts.contacts_id.relation, contacts.contacts_id.name, contacts.contacts_id.phone, 
                contacts.contacts_id.email, contacts.contacts_id.notes, 
                therapists.therapists_id.id, therapists.therapists_id.user.first_name, therapists.therapists_id.user.last_name, 
                documents.directus_files_id.id, documents.directus_files_id.filename_disk, documents.directus_files_id.filename_download`, 
        sort: 'sessions.numSession'
    };
    private endpoint = '/items/patients';

    constructor(
        private directusService: DirectusService<Patient>,
        private directusFileService: DirectusFileService
    ) { }

    index(apiParams?: DirectusQueryParams): Observable<Patient[]> {
        if(apiParams) this.apiParams = { ...this.apiParams, ...apiParams };
        return this.directusService.index(this.endpoint, this.apiParams);
    }

    create(params:any): Observable<Patient> {
        return this.directusService.create(this.endpoint, params);
    }

    view(id:string): Observable<Patient> {
        return this.directusService.view(this.endpoint, id, this.apiParamsView);
    }

    patch(id:string, params:any): Observable<Patient> {
        return this.directusService.patch(this.endpoint, id, params, this.apiParamsView);
    }

    uploadFiles(files:File[], patient:Patient): Observable<Patient> {
        return this.directusFileService.upload(files).pipe(
            map((files:DirectusFile[] | DirectusFile) => {
                let documents:{ directus_files_id:DirectusFile}[] = [];

                // Push uploaded file/s
                if(Array.isArray(files)) files.map((file:DirectusFile) => documents.push({ directus_files_id: file } ));
                else documents.push({ directus_files_id: files } );
                
                // Push existent files
                patient.documents.map((document:DirectusFile) => documents.push({ directus_files_id: document } ));
                return documents;
            }),
            switchMap((documents) => this.patch(patient.id, { documents:documents }))
        )
    }

    deleteFile(id:string, patient:Patient): Observable<Patient> {
        return this.directusFileService.delete(id).pipe(
            map(() => {
                let documents:{ directus_files_id:DirectusFile}[] = [];

                // Push existent files except deleted
                patient.documents.map((document:DirectusFile) => {
                    if(id != document.id) documents.push({ directus_files_id: document } );
                });
                return documents;
            }),
            switchMap((documents) => this.patch(patient.id, { documents:documents }))
        );
    }
}


import { Component } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs';
import { StickyHeader } from '../../../shared/interfaces/sticky-header.interface';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss']
})
export class StickyHeaderComponent {
  	public stickyHeader:StickyHeader = { title: '' };

    constructor( 
	  	private router: Router, 
      	private location: Location
    ) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.addHeadersData(this.router.routerState.snapshot.root, []);
		});
	}

    goBack() {
      	this.location.back();
    }

	private addHeadersData(route: ActivatedRouteSnapshot, parentUrl: string[]) {
        const routeUrl = parentUrl.concat(route.url.map(url => url.path));
        const stickyHeader = (route.data['stickyHeader']) as StickyHeader;
		
		// Get appHeader data
		if(stickyHeader) this.stickyHeader = stickyHeader;            

        if (route.firstChild) {
            this.addHeadersData(route.firstChild, routeUrl);
        }
    }
}

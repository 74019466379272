<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header pb-0">
        <a [routerLink]="['/']" class="app-logo">
            <img src="assets/media/logo.png" alt="logo" class="app-logo-sidebar">
        </a>
        <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button>
    </div>
 

    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>
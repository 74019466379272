import { Pipe, PipeTransform } from '@angular/core';
import { sessionTypeOptions } from 'src/app/modules/session/models/session-type.model';

@Pipe({
    name: 'sessionType'
})
export class SessionTypePipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): unknown {
        const sessionType = sessionTypeOptions.find((sessionType) => sessionType.id == value);
        
        return (sessionType) ? sessionType.name : '';
    }

}

import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation({
            today: $localize`Hoy`,
            dayNames: [
                $localize`Domingo`, $localize`Lunes`, $localize`Martes`, $localize`Miércoles`, $localize`Jueves`, $localize`Viernes`, $localize`Sábado`
            ],
            dayNamesShort: [
                $localize`dom.`, $localize`lun.`, $localize`mart.`, $localize`miérc.`, $localize`juev.`, $localize`vier.`, $localize`sáb.`
            ],
            dayNamesMin: [
                $localize`do.`, $localize`lu.`, $localize`ma.`, $localize`mi.`, $localize`ju.`, $localize`vi.`, $localize`sá.`
            ],
            monthNames: [
                $localize`Enero`, $localize`Febrero`, $localize`Marzo`, $localize`Abril`, $localize`Mayo`, $localize`Junio`, 
                $localize`Julio`, $localize`Agosto`, $localize`Setiembre`, $localize`Octubre`, $localize`Noviembre`, $localize`Diciembre`
            ],
            monthNamesShort: [
                'en.', 'febr.', 'mzo.', 'abr.', 'my.', 'jun.', 'jul.', 'ag.', 'set.', 'oct.', 'nov.','dic.'
            ],
            clear: $localize`Borrar`,
            dateFormat: 'dd/mm/yy',
            weekHeader: $localize`Semana`,
            firstDayOfWeek: 1,
            accept: $localize`Aceptar`,
            reject: $localize`Rechazar`,
        });
    }

}

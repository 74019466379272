<div class="layout-tab-bar">    
    <div class="layout-tab-bar-wrapper">
        <div class="card fixed w-full bottom-0 border-0 border-noround p-0 text-gray-600 z-5">
            <div class="flex flex-row flex-wrap justify-content-between justify-content-center card-container">
                <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center font-bold p-1" [routerLink]="['/patient']" [routerLinkActive]="['surface-900', 'text-white']">
                    <div class="m-2 mb-0 text-center align-items-center justify-content-center cursor-pointer">
                        <i class="pi pi-user pb-0 text-4xl"></i>
                        <p i18n class="text-sm pb-0">Pacientes</p>
                    </div>
                </div>
                <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center font-bold p-1" [routerLink]="['/session']" [routerLinkActive]="['surface-900', 'text-white']">
                    <div class="m-2 mb-0 text-center align-items-center justify-content-center cursor-pointer">
                        <i class="pi pi-calendar pb-0 text-4xl"></i>
                        <p i18n class="text-sm pb-0">Sesiones</p>
                    </div>
                </div>
                <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center font-bold p-1 cursor-pointer" (click)="onProfileButtonClick()">
                    <div class="m-2 mb-0 text-center align-items-center justify-content-center">
                        <i class="pi pi-cog pb-0 text-4xl"></i>
                        <p i18n class="text-sm pb-0">Ajustes</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
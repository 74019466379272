import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Patient } from '../../models/patient.model';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DirectusFile } from 'src/app/shared/models/directus-file.model';
import { map } from 'rxjs';
import { PatientService } from '../../services/patient.service';

@Component({
    selector: 'app-patient-view-detail',
    templateUrl: './patient-view-detail.component.html',
    styleUrls: ['./patient-view-detail.component.scss']
})
export class PatientViewDetailComponent {

    @Input() avatarBackButton:boolean | undefined = false;
    @Input() extendedDetail:boolean | undefined = false;
    @Input() showPatientNumber:boolean | undefined = false;
    @Input() showCard:boolean | undefined = true;

    public idPatient: string;
    public patient: Patient;

    constructor(
		private readonly router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private patientService: PatientService
    ) { 
		
	}

    ngOnInit() {
        this.router.events.pipe(
            map(e => this._getParams(this.activatedRoute)),
        ).subscribe((params:any) => {
            if(!this.idPatient && this.idPatient != params?.id) {
                this.idPatient = params.id;

                this.patientService.view(this.idPatient).subscribe((patient:Patient) => this.patient = patient);                    
            }
      });
    }

    getAvatar(patient:Patient) {
        if(patient && patient.user.avatar) return environment.apiHost + '/assets/' + (patient.user.avatar as DirectusFile).id;
        else return '/assets/media/avatars/blank.png';
    }

    goBack() {
        this.location.back();
    }


    /***************************
    ***** PRIVATE FUNCTIONS ****
    ****************************/
    private _getParams(route: ActivatedRoute): Params {
		// route param names (eg /a/:personId) must be ditinct within
		// a route otherwise they'll be overwritten
		let params = route.snapshot.params
		params = { ...route.snapshot.queryParams, ...params}
		if(route.children){
			for(let r of route.children){
				params = {...this._getParams(r), ...params};        
			}
		}
		return params;
	}

}
